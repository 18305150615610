import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as Pinia from 'pinia/dist/pinia.mjs'
import './assets/styles/main.scss'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { vAos } from './directives/aos'

// Font Awesome初始化
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserSecret, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faWeixin, faGithub } from '@fortawesome/free-brands-svg-icons'

library.add(faUserSecret, faEnvelope, faPhone, faWeixin, faGithub)

// 创建应用实例
const app = createApp(App)

// 注册全局组件
app.component('font-awesome-icon', FontAwesomeIcon)

// 注册全局指令
app.directive('aos', vAos)

// 添加图片懒加载指令
app.directive('lazy', {
  mounted(el, binding) {
    // 创建一个 IntersectionObserver 实例
    const observer = new IntersectionObserver((entries) => {
      // 当元素进入视口
      if (entries[0].isIntersecting) {
        // 设置图片源
        el.src = binding.value
        // 图片加载后，取消观察
        observer.unobserve(el)
      }
    })
    // 开始观察元素
    observer.observe(el)
  }
})

// 注册Pinia状态管理
const pinia = Pinia.createPinia()
app.use(pinia)

// 注册路由
app.use(router)

// 挂载应用
app.mount('#app')

// 初始化AOS
AOS.init({
  duration: 800,
  easing: 'ease-in-out',
  once: true
}) 