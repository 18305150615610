import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modern-footer" }
const _hoisted_2 = { class: "footer-content wp1280" }
const _hoisted_3 = { class: "footer-main" }
const _hoisted_4 = { class: "footer-nav" }
const _hoisted_5 = { class: "nav-group" }
const _hoisted_6 = { class: "nav-links" }
const _hoisted_7 = { class: "nav-column" }
const _hoisted_8 = { class: "nav-column" }
const _hoisted_9 = { class: "nav-group" }
const _hoisted_10 = { class: "nav-links" }
const _hoisted_11 = { class: "nav-column" }
const _hoisted_12 = { class: "nav-group" }
const _hoisted_13 = { class: "nav-links" }
const _hoisted_14 = { class: "nav-column" }


export default /*@__PURE__*/_defineComponent({
  __name: 'FooterSection',
  setup(__props) {

// 无需特殊逻辑

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "footer-waves" }, [
      _createElementVNode("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 1440 320",
        preserveAspectRatio: "none"
      }, [
        _createElementVNode("path", {
          fill: "rgba(255,255,255,0.05)",
          d: "M0,96L48,112C96,128,192,160,288,160C384,160,480,128,576,112C672,96,768,96,864,112C960,128,1056,160,1152,165.3C1248,171,1344,149,1392,138.7L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        })
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[14] || (_cache[14] = _createStaticVNode("<div class=\"footer-info\" data-v-66eb3477><div class=\"qr-section\" data-v-66eb3477><div class=\"qr-cards\" data-v-66eb3477><div class=\"qr-card\" data-v-66eb3477><img src=\"/static/assets/images/about/wechat.png\" alt=\"微信公众号\" class=\"qr-image\" data-v-66eb3477><p class=\"qr-title\" data-v-66eb3477>微信公众号</p><p class=\"qr-desc\" data-v-66eb3477>扫码关注，了解更多</p></div><div class=\"qr-card\" data-v-66eb3477><img src=\"/static/assets/images/about/wechat.png\" alt=\"企业微信\" class=\"qr-image\" data-v-66eb3477><p class=\"qr-title\" data-v-66eb3477>企业微信</p><p class=\"qr-desc\" data-v-66eb3477>扫码咨询，即刻沟通</p></div></div></div></div>", 1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[5] || (_cache[5] = _createElementVNode("h4", null, "产品服务", -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_router_link, { to: "/product.html#content-creation" }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("内容创作")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, { to: "/product.html#solution-writing" }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("方案撰写")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, { to: "/product.html#smart-qa" }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("智能问答")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_router_link, { to: "/product.html#model-consulting" }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("大模型咨询")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, { to: "/product.html#model-development" }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("大模型开发")
                  ])),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[9] || (_cache[9] = _createElementVNode("h4", null, "解决方案", -1)),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_router_link, { to: "/solution.html#solution-148" }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("企业级知识中枢")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, { to: "/solution.html#solution-149" }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("教育认知引擎")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, { to: "/solution.html#solution-150" }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("新一代智能客服")
                  ])),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[13] || (_cache[13] = _createElementVNode("h4", null, "关于我们", -1)),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_router_link, { to: "/about" }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode("公司介绍")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, { to: "/about" }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode("联系我们")
                  ])),
                  _: 1
                }),
                _cache[12] || (_cache[12] = _createElementVNode("a", {
                  href: "#",
                  class: "join-us"
                }, "加入我们", -1))
              ])
            ])
          ])
        ])
      ])
    ]),
    _cache[16] || (_cache[16] = _createStaticVNode("<div class=\"footer-bottom\" data-v-66eb3477><div class=\"wp1280\" data-v-66eb3477><div class=\"bottom-content\" data-v-66eb3477><p class=\"copyright\" data-v-66eb3477>© 2024 青岛维熙人工智能科技有限公司</p><div class=\"beian-links\" data-v-66eb3477><a href=\"https://beian.miit.gov.cn/\" target=\"_blank\" rel=\"nofollow\" class=\"icp-link\" data-v-66eb3477>鲁ICP备2025148125号-1</a></div></div></div></div>", 1))
  ]))
}
}

})