import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

// 使用动态导入实现路由懒加载
const HomePage = () => import(/* webpackChunkName: "home" */ '@/views/HomePage.vue')
const ProductPage = () => import(/* webpackChunkName: "product" */ '@/views/ProductPage.vue')
const SolutionPage = () => import(/* webpackChunkName: "solution" */ '@/views/SolutionPage.vue')
const AIAssistantPage = () => import(/* webpackChunkName: "ai" */ '@/views/AIAssistantPage.vue')
const AboutPage = () => import(/* webpackChunkName: "about" */ '@/views/AboutPage.vue')

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: {
      title: '首页-青岛维熙科技',
      keepAlive: true
    }
  },
  {
    path: '/product.html',
    name: 'Product',
    component: ProductPage,
    meta: {
      title: '产品/服务-青岛维熙科技',
      keepAlive: true
    }
  },
  {
    path: '/solution.html',
    name: 'Solution',
    component: SolutionPage,
    meta: {
      title: '解决方案-青岛维熙科技',
      keepAlive: true
    }
  },
  {
    path: '/AIAssistant.html',
    name: 'AIAssistant',
    component: AIAssistantPage,
    meta: {
      title: 'AI体验-青岛维熙科技',
      keepAlive: true
    }
  },
  {
    path: '/about.html',
    name: 'About',
    component: AboutPage,
    meta: {
      title: '关于我们-青岛维熙科技',
      keepAlive: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

// 全局路由守卫，动态设置页面标题
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title as string
  }
  next()
})

export default router 