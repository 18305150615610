import { defineComponent as _defineComponent } from 'vue'
import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, unref as _unref, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/ui/icon_4.png'


const _hoisted_1 = { class: "header-relative" }
const _hoisted_2 = { class: "wp1280" }
const _hoisted_3 = { class: "header-nav" }
const _hoisted_4 = { class: "header-left" }
const _hoisted_5 = {
  alt: "维熙科技",
  src: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
}
const _hoisted_6 = { class: "header-right" }
const _hoisted_7 = { class: "nav-list" }
const _hoisted_8 = ["onMouseenter"]
const _hoisted_9 = { class: "nav-link" }
const _hoisted_10 = { class: "mega-dropdown-container" }
const _hoisted_11 = { class: "mega-dropdown-content" }
const _hoisted_12 = { class: "mega-section" }
const _hoisted_13 = { class: "mega-section-grid" }
const _hoisted_14 = { class: "mega-category" }
const _hoisted_15 = { class: "mega-category-content" }
const _hoisted_16 = { class: "mega-item" }
const _hoisted_17 = { class: "mega-item-header" }
const _hoisted_18 = {
  alt: "工作+AI",
  src: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
}
const _hoisted_19 = { class: "mega-item-list" }
const _hoisted_20 = { class: "mega-item" }
const _hoisted_21 = { class: "mega-item-header" }
const _hoisted_22 = {
  alt: "业务+AI",
  src: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
}
const _hoisted_23 = { class: "mega-item-list" }
const _hoisted_24 = { class: "mega-category" }
const _hoisted_25 = { class: "mega-category-content" }
const _hoisted_26 = { class: "mega-item featured" }
const _hoisted_27 = { class: "mega-item-header" }
const _hoisted_28 = {
  alt: "大模型咨询",
  src: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
}
const _hoisted_29 = { class: "mega-category" }
const _hoisted_30 = { class: "mega-category-content" }
const _hoisted_31 = { class: "mega-item featured" }
const _hoisted_32 = { class: "mega-item-header" }
const _hoisted_33 = {
  alt: "大模型开发",
  src: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
}
const _hoisted_34 = { class: "mobile-menu-inner" }
const _hoisted_35 = { class: "mobile-menu-header" }
const _hoisted_36 = { class: "logo" }
const _hoisted_37 = {
  alt: "维熙科技",
  src: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
}
const _hoisted_38 = { class: "mobile-menu-content" }
const _hoisted_39 = ["onClick"]
const _hoisted_40 = { key: 0 }
const _hoisted_41 = { key: 1 }
const _hoisted_42 = { key: 2 }

import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { useMainStore } from '@/stores/main'


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderNav',
  setup(__props) {

const store = useMainStore()
const route = useRoute()
const isSticky = ref(false)
const activeDropdown = ref<number | null>(null)
const mobileExpandedItem = ref<number | null>(null)

// 导航项目
const navItems = ref([
  { label: '首页', path: '/', hasDropdown: false, type: 'home' },
  { label: '产品/服务', path: '/product.html', hasDropdown: true, type: 'product' },
  { label: '解决方案', path: '/solution.html', hasDropdown: true, type: 'solution' },
  { label: 'AI 体验', path: '/AIAssistant.html', hasDropdown: true, type: 'ai' },
  { label: '联系我们', path: '/about.html', hasDropdown: false, type: 'about' }
])

// 检查当前路由是否激活
const isActive = (path: string) => {
  if (path === '/') {
    return route.path === path
  }
  return route.path.startsWith(path.split('.')[0])
}

// 新增关闭下拉菜单方法
const closeDropdown = () => {
  activeDropdown.value = null
}

// 处理下拉菜单显示
const handleMouseEnter = (index: number) => {
  if (navItems.value[index].hasDropdown) {
    activeDropdown.value = index
  }
}

const handleMouseLeave = () => {
  activeDropdown.value = null
}

// 移动端菜单处理
const toggleMobileMenu = () => {
  store.toggleMobileMenu()
}

// 也可以添加一个关闭按钮专用的方法
const closeMobileMenu = () => {
  store.setShowMobileMenu(false)
}

const mobileNavClick = (item: any) => {
  if (!item.hasDropdown) {
    store.toggleMobileMenu()
    return
  }
  
  const index = navItems.value.findIndex(nav => nav.label === item.label)
  mobileExpandedItem.value = mobileExpandedItem.value === index ? null : index
}

// 监听滚动，控制导航栏样式
const handleScroll = () => {
  isSticky.value = window.scrollY > 50
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  handleScroll() // 初始检查
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sticky-wrapper", { 'is-sticky': isSticky.value }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["header", { 'stuck': isSticky.value }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_router_link, {
                to: "/",
                class: "logo"
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("img", _hoisted_5, null, 512), [
                    [_directive_lazy, require('@/assets/images/ivxilogo.png')]
                  ])
                ]),
                _: 1
              }),
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "logo-tag" }, [
                _createElementVNode("span", { class: "txt" }, "维熙科技")
              ], -1))
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navItems.value, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: _normalizeClass(["nav-item", { 
                    'is-active': isActive(item.path), 
                    'has-dropdown': item.hasDropdown 
                  }]),
                    onMouseenter: ($event: any) => (handleMouseEnter(index)),
                    onMouseleave: handleMouseLeave
                  }, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_router_link, {
                        to: item.path
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ]),
                    (item.type === 'product')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(["mega-dropdown", {'show': activeDropdown.value === index}])
                        }, [
                          _createElementVNode("div", {
                            class: "mega-dropdown-overlay",
                            onClick: closeDropdown
                          }),
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("div", _hoisted_12, [
                                _cache[13] || (_cache[13] = _createElementVNode("div", { class: "mega-section-header" }, [
                                  _createElementVNode("h2", null, "产品与服务"),
                                  _createElementVNode("p", null, "为企业提供全方位的AI解决方案")
                                ], -1)),
                                _createElementVNode("div", _hoisted_13, [
                                  _createElementVNode("div", _hoisted_14, [
                                    _cache[10] || (_cache[10] = _createElementVNode("h3", null, "本地大模型应用平台", -1)),
                                    _createElementVNode("div", _hoisted_15, [
                                      _createElementVNode("div", _hoisted_16, [
                                        _createElementVNode("div", _hoisted_17, [
                                          _withDirectives(_createElementVNode("img", _hoisted_18, null, 512), [
                                            [_directive_lazy, require('@/assets/images/ui/icon_1.png')]
                                          ]),
                                          _cache[1] || (_cache[1] = _createElementVNode("h4", null, "工作+AI", -1)),
                                          _cache[2] || (_cache[2] = _createElementVNode("span", null, "提升全员工作效率", -1))
                                        ]),
                                        _createElementVNode("div", _hoisted_19, [
                                          _createVNode(_component_router_link, { to: "/product/content-creation" }, {
                                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                                              _createTextVNode("内容创作")
                                            ])),
                                            _: 1
                                          }),
                                          _createVNode(_component_router_link, { to: "/product/solution-writing" }, {
                                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                                              _createTextVNode("方案撰写")
                                            ])),
                                            _: 1
                                          })
                                        ])
                                      ]),
                                      _createElementVNode("div", _hoisted_20, [
                                        _createElementVNode("div", _hoisted_21, [
                                          _withDirectives(_createElementVNode("img", _hoisted_22, null, 512), [
                                            [_directive_lazy, require('@/assets/images/ui/icon_5.png')]
                                          ]),
                                          _cache[5] || (_cache[5] = _createElementVNode("h4", null, "业务+AI", -1)),
                                          _cache[6] || (_cache[6] = _createElementVNode("span", null, "构建企业知识库", -1))
                                        ]),
                                        _createElementVNode("div", _hoisted_23, [
                                          _createVNode(_component_router_link, { to: "/product/smart-qa" }, {
                                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                                              _createTextVNode("智能问答")
                                            ])),
                                            _: 1
                                          }),
                                          _createVNode(_component_router_link, { to: "/product/capability-expansion" }, {
                                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                                              _createTextVNode("能力拓展")
                                            ])),
                                            _: 1
                                          }),
                                          _createVNode(_component_router_link, { to: "/product/private-model" }, {
                                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                                              _createTextVNode("私有模型")
                                            ])),
                                            _: 1
                                          })
                                        ])
                                      ])
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_24, [
                                    _cache[11] || (_cache[11] = _createElementVNode("h3", null, "大模型咨询", -1)),
                                    _createElementVNode("div", _hoisted_25, [
                                      _createElementVNode("div", _hoisted_26, [
                                        _createElementVNode("div", _hoisted_27, [
                                          _withDirectives(_createElementVNode("img", _hoisted_28, null, 512), [
                                            [_directive_lazy, require('@/assets/images/neixun.png')]
                                          ])
                                        ])
                                      ])
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_29, [
                                    _cache[12] || (_cache[12] = _createElementVNode("h3", null, "大模型开发", -1)),
                                    _createElementVNode("div", _hoisted_30, [
                                      _createElementVNode("div", _hoisted_31, [
                                        _createElementVNode("div", _hoisted_32, [
                                          _withDirectives(_createElementVNode("img", _hoisted_33, null, 512), [
                                            [_directive_lazy, require('@/assets/images/kaifa.png')]
                                          ])
                                        ])
                                      ])
                                    ])
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ], 2))
                      : _createCommentVNode("", true),
                    (item.type === 'solution')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass(["mega-dropdown", {'show': activeDropdown.value === index}])
                        }, [
                          _createElementVNode("div", {
                            class: "mega-dropdown-overlay",
                            onClick: closeDropdown
                          }),
                          _cache[14] || (_cache[14] = _createStaticVNode("<div class=\"mega-dropdown-container\"><div class=\"mega-dropdown-content\"><div class=\"mega-section\"><div class=\"mega-section-header\"><h2>解决方案</h2><p>为不同行业提供专业的AI解决方案</p></div><div class=\"mega-section-grid\"><div class=\"mega-category\"><h3>企业级解决方案</h3><div class=\"mega-category-content\"><div class=\"mega-item\"><div class=\"mega-item-header\"><img src=\"" + _imports_0 + "\" alt=\"企业级知识中枢\"><h4>企业级知识中枢</h4><span>智能知识管理</span></div></div><div class=\"mega-item\"><div class=\"mega-item-header\"><img src=\"" + _imports_0 + "\" alt=\"教育认知引擎\"><h4>教育认知引擎</h4><span>智慧教育助手</span></div></div><div class=\"mega-item\"><div class=\"mega-item-header\"><img src=\"" + _imports_0 + "\" alt=\"新一代智能客服\"><h4>新一代智能客服</h4><span>全天候服务</span></div></div></div></div></div></div></div></div>", 1))
                        ], 2))
                      : _createCommentVNode("", true),
                    (item.type === 'ai')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 2,
                          class: _normalizeClass(["mega-dropdown", {'show': activeDropdown.value === index}])
                        }, [
                          _createElementVNode("div", {
                            class: "mega-dropdown-overlay",
                            onClick: closeDropdown
                          }),
                          _cache[15] || (_cache[15] = _createStaticVNode("<div class=\"mega-dropdown-container\"><div class=\"mega-dropdown-content\"><div class=\"mega-section\"><div class=\"mega-section-header\"><h2>AI 工具体验</h2><p>体验前沿AI技术带来的便捷</p></div><div class=\"mega-section-grid\"><div class=\"mega-category\"><h3>智能工具</h3><div class=\"mega-category-content\"><div class=\"mega-item\"><div class=\"mega-item-header\"><img src=\"" + _imports_0 + "\" alt=\"AI翻译\"><h4>AI翻译</h4><span>多语言智能翻译</span></div></div><div class=\"mega-item\"><div class=\"mega-item-header\"><img src=\"" + _imports_0 + "\" alt=\"代码解释器\"><h4>代码解释器</h4><span>智能代码分析</span></div></div><div class=\"mega-item\"><div class=\"mega-item-header\"><img src=\"" + _imports_0 + "\" alt=\"会议纪要\"><h4>会议纪要助手</h4><span>自动会议记录</span></div></div></div></div></div></div></div></div>", 1))
                        ], 2))
                      : _createCommentVNode("", true)
                  ], 42, _hoisted_8))
                }), 128))
              ]),
              _createElementVNode("div", {
                class: "mobile-toggle",
                onClick: toggleMobileMenu
              }, _cache[16] || (_cache[16] = [
                _createElementVNode("span", null, null, -1),
                _createElementVNode("span", null, null, -1),
                _createElementVNode("span", null, null, -1)
              ]))
            ])
          ])
        ])
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["mobile-menu", {'show': _unref(store).showMobileMenu}])
    }, [
      _createElementVNode("div", _hoisted_34, [
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("div", _hoisted_36, [
            _withDirectives(_createElementVNode("img", _hoisted_37, null, 512), [
              [_directive_lazy, require('@/assets/images/ivxilogo.png')]
            ])
          ]),
          _createElementVNode("div", {
            class: "close-btn",
            onClick: toggleMobileMenu
          }, "×")
        ]),
        _createElementVNode("div", _hoisted_38, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navItems.value, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(["mobile-nav-item", {'active': isActive(item.path)}])
            }, [
              _createElementVNode("div", {
                class: "mobile-nav-link",
                onClick: ($event: any) => (mobileNavClick(item))
              }, [
                _createElementVNode("span", null, _toDisplayString(item.label), 1),
                (item.hasDropdown)
                  ? (_openBlock(), _createElementBlock("svg", {
                      key: 0,
                      viewBox: "0 0 24 24",
                      width: "24",
                      height: "24",
                      class: _normalizeClass(["arrow", {'open': mobileExpandedItem.value === index}])
                    }, _cache[17] || (_cache[17] = [
                      _createElementVNode("path", { d: "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" }, null, -1)
                    ]), 2))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_39),
              _createElementVNode("div", {
                class: _normalizeClass(["mobile-submenu", {'open': mobileExpandedItem.value === index}])
              }, [
                (item.type === 'product')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                      _cache[23] || (_cache[23] = _createElementVNode("h4", null, "工作+AI", -1)),
                      _createVNode(_component_router_link, {
                        to: "/product/content-creation",
                        class: "mobile-submenu-item"
                      }, {
                        default: _withCtx(() => _cache[18] || (_cache[18] = [
                          _createTextVNode("内容创作")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_router_link, {
                        to: "/product/solution-writing",
                        class: "mobile-submenu-item"
                      }, {
                        default: _withCtx(() => _cache[19] || (_cache[19] = [
                          _createTextVNode("方案撰写")
                        ])),
                        _: 1
                      }),
                      _cache[24] || (_cache[24] = _createElementVNode("h4", null, "业务+AI", -1)),
                      _createVNode(_component_router_link, {
                        to: "/product/smart-qa",
                        class: "mobile-submenu-item"
                      }, {
                        default: _withCtx(() => _cache[20] || (_cache[20] = [
                          _createTextVNode("智能问答")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_router_link, {
                        to: "/product/capability-expansion",
                        class: "mobile-submenu-item"
                      }, {
                        default: _withCtx(() => _cache[21] || (_cache[21] = [
                          _createTextVNode("能力拓展")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_router_link, {
                        to: "/product/private-model",
                        class: "mobile-submenu-item"
                      }, {
                        default: _withCtx(() => _cache[22] || (_cache[22] = [
                          _createTextVNode("私有模型")
                        ])),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                (item.type === 'solution')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                      _createVNode(_component_router_link, {
                        to: "/solution/enterprise-knowledge",
                        class: "mobile-submenu-item"
                      }, {
                        default: _withCtx(() => _cache[25] || (_cache[25] = [
                          _createTextVNode("企业级知识中枢")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_router_link, {
                        to: "/solution/education-engine",
                        class: "mobile-submenu-item"
                      }, {
                        default: _withCtx(() => _cache[26] || (_cache[26] = [
                          _createTextVNode("教育认知引擎")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_router_link, {
                        to: "/solution/smart-service",
                        class: "mobile-submenu-item"
                      }, {
                        default: _withCtx(() => _cache[27] || (_cache[27] = [
                          _createTextVNode("新一代智能客服")
                        ])),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                (item.type === 'ai')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                      _createVNode(_component_router_link, {
                        to: "/ai/translator",
                        class: "mobile-submenu-item"
                      }, {
                        default: _withCtx(() => _cache[28] || (_cache[28] = [
                          _createTextVNode("AI翻译")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_router_link, {
                        to: "/ai/code-interpreter",
                        class: "mobile-submenu-item"
                      }, {
                        default: _withCtx(() => _cache[29] || (_cache[29] = [
                          _createTextVNode("代码解释器")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_router_link, {
                        to: "/ai/meeting-summary",
                        class: "mobile-submenu-item"
                      }, {
                        default: _withCtx(() => _cache[30] || (_cache[30] = [
                          _createTextVNode("会议纪要助手")
                        ])),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ], 2)
            ], 2))
          }), 128))
        ])
      ])
    ], 2)
  ], 2))
}
}

})