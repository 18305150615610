<template>
  <div id="app">
    <HeaderNav />
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <FooterSection />
    <BackToTop />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import HeaderNav from '@/components/HeaderNav.vue'
import FooterSection from '@/components/FooterSection.vue'
import BackToTop from '@/components/BackToTop.vue'

// 预加载关键图片资源
const preloadImages = () => {
  const criticalImages = [
    '/static/assets/images/banner/front_page_1.jpg',
    '/static/assets/images/ivxilogo.png',
  ];

  criticalImages.forEach(src => {
    const img = new Image();
    img.src = src;
  });
};

// 优化字体加载
const optimizeFontLoading = () => {
  // 创建字体预加载链接
  const fontPreloadLink = document.createElement('link');
  fontPreloadLink.rel = 'preload';
  fontPreloadLink.as = 'font';
  fontPreloadLink.type = 'font/woff2';
  fontPreloadLink.href = '/static/assets/fonts/main-font.woff2';
  fontPreloadLink.crossOrigin = 'anonymous';
  document.head.appendChild(fontPreloadLink);
};

// 设置资源加载优先级
const setResourcePriorities = () => {
  // 将关键CSS设置为高优先级
  const cssLinks = document.querySelectorAll('link[rel="stylesheet"]');
  cssLinks.forEach(link => {
    // 使用类型断言
    const linkElement = link as HTMLLinkElement;
    if (linkElement.href.includes('main')) {
      linkElement.setAttribute('fetchpriority', 'high');
    }
  });
};

onMounted(() => {
  // 执行性能优化
  preloadImages();
  optimizeFontLoading();
  setResourcePriorities();
  
  // 延迟加载非关键资源
  setTimeout(() => {
    // 加载分析脚本等非关键资源
    const analyticsScript = document.createElement('script');
    analyticsScript.src = '/static/assets/js/analytics.js';
    analyticsScript.defer = true;
    document.body.appendChild(analyticsScript);
  }, 3000);
});
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.main-content {
  flex: 1;
}
</style>
