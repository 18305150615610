import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

import { onMounted } from 'vue';
import HeaderNav from '@/components/HeaderNav.vue'
import FooterSection from '@/components/FooterSection.vue'
import BackToTop from '@/components/BackToTop.vue'

// 预加载关键图片资源

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const preloadImages = () => {
  const criticalImages = [
    '/static/assets/images/banner/front_page_1.jpg',
    '/static/assets/images/ivxilogo.png',
  ];

  criticalImages.forEach(src => {
    const img = new Image();
    img.src = src;
  });
};

// 优化字体加载
const optimizeFontLoading = () => {
  // 创建字体预加载链接
  const fontPreloadLink = document.createElement('link');
  fontPreloadLink.rel = 'preload';
  fontPreloadLink.as = 'font';
  fontPreloadLink.type = 'font/woff2';
  fontPreloadLink.href = '/static/assets/fonts/main-font.woff2';
  fontPreloadLink.crossOrigin = 'anonymous';
  document.head.appendChild(fontPreloadLink);
};

// 设置资源加载优先级
const setResourcePriorities = () => {
  // 将关键CSS设置为高优先级
  const cssLinks = document.querySelectorAll('link[rel="stylesheet"]');
  cssLinks.forEach(link => {
    // 使用类型断言
    const linkElement = link as HTMLLinkElement;
    if (linkElement.href.includes('main')) {
      linkElement.setAttribute('fetchpriority', 'high');
    }
  });
};

onMounted(() => {
  // 执行性能优化
  preloadImages();
  optimizeFontLoading();
  setResourcePriorities();
  
  // 延迟加载非关键资源
  setTimeout(() => {
    // 加载分析脚本等非关键资源
    const analyticsScript = document.createElement('script');
    analyticsScript.src = '/static/assets/js/analytics.js';
    analyticsScript.defer = true;
    document.body.appendChild(analyticsScript);
  }, 3000);
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(HeaderNav),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ], 1024))
      ]),
      _: 1
    }),
    _createVNode(FooterSection),
    _createVNode(BackToTop)
  ]))
}
}

})