<template>
  <div class="sticky-wrapper" :class="{ 'is-sticky': isSticky }">
    <div class="header" :class="{ 'stuck': isSticky }">
      <div class="header-relative">
        <div class="wp1280">
          <div class="header-nav">
            <div class="header-left">
              <router-link to="/" class="logo">
                <img v-lazy="require('@/assets/images/ivxilogo.png')" alt="维熙科技" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7">
              </router-link>
              <div class="logo-tag">
                <span class="txt">维熙科技</span>
              </div>
            </div>
            <div class="header-right">
              <div class="nav-list">
                <div 
                  v-for="(item, index) in navItems" 
                  :key="index" 
                  class="nav-item" 
                  :class="{ 
                    'is-active': isActive(item.path), 
                    'has-dropdown': item.hasDropdown 
                  }"
                  @mouseenter="handleMouseEnter(index)"
                  @mouseleave="handleMouseLeave"
                >
                  <div class="nav-link">
                    <router-link :to="item.path">{{ item.label }}</router-link>
                  </div>
                  
                  <!-- 产品/服务下拉菜单 -->
                  <div v-if="item.type === 'product'" class="mega-dropdown" :class="{'show': activeDropdown === index}">
                    <div class="mega-dropdown-overlay" @click="closeDropdown"></div>
                    <div class="mega-dropdown-container">
                      <div class="mega-dropdown-content">
                        <div class="mega-section">
                          <div class="mega-section-header">
                            <h2>产品与服务</h2>
                            <p>为企业提供全方位的AI解决方案</p>
                          </div>
                          <div class="mega-section-grid">
                            <!-- 本地大模型应用平台 -->
                            <div class="mega-category">
                              <h3>本地大模型应用平台</h3>
                              <div class="mega-category-content">
                                <div class="mega-item">
                                  <div class="mega-item-header">
                                    <img v-lazy="require('@/assets/images/ui/icon_1.png')" alt="工作+AI" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7">
                                    <h4>工作+AI</h4>
                                    <span>提升全员工作效率</span>
                                  </div>
                                  <div class="mega-item-list">
                                    <router-link to="/product/content-creation">内容创作</router-link>
                                    <router-link to="/product/solution-writing">方案撰写</router-link>
                                  </div>
                                </div>
                                
                                <div class="mega-item">
                                  <div class="mega-item-header">
                                    <img v-lazy="require('@/assets/images/ui/icon_5.png')" alt="业务+AI" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7">
                                    <h4>业务+AI</h4>
                                    <span>构建企业知识库</span>
                                  </div>
                                  <div class="mega-item-list">
                                    <router-link to="/product/smart-qa">智能问答</router-link>
                                    <router-link to="/product/capability-expansion">能力拓展</router-link>
                                    <router-link to="/product/private-model">私有模型</router-link>
                                  </div>
                                </div>
                              </div>
                            </div>
                            
                            <!-- 大模型咨询 -->
                            <div class="mega-category">
                              <h3>大模型咨询</h3>
                              <div class="mega-category-content">
                                <div class="mega-item featured">
                                  <div class="mega-item-header">
                                    <img v-lazy="require('@/assets/images/neixun.png')" alt="大模型咨询" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7">
                                    <!-- <h4>咨询服务</h4>
                                    <span>专业指导</span> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                            
                            <!-- 大模型开发 -->
                            <div class="mega-category">
                              <h3>大模型开发</h3>
                              <div class="mega-category-content">
                                <div class="mega-item featured">
                                  <div class="mega-item-header">
                                    <img v-lazy="require('@/assets/images/kaifa.png')" alt="大模型开发" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7">
                                    <!-- <h4>开发服务</h4>
                                    <span>定制解决方案</span> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <!-- 解决方案下拉菜单 -->
                  <div v-if="item.type === 'solution'" class="mega-dropdown" :class="{'show': activeDropdown === index}">
                    <div class="mega-dropdown-overlay" @click="closeDropdown"></div>
                    <div class="mega-dropdown-container">
                      <div class="mega-dropdown-content">
                        <div class="mega-section">
                          <div class="mega-section-header">
                            <h2>解决方案</h2>
                            <p>为不同行业提供专业的AI解决方案</p>
                          </div>
                          <div class="mega-section-grid">
                            <div class="mega-category">
                              <h3>企业级解决方案</h3>
                              <div class="mega-category-content">
                                <div class="mega-item">
                                  <div class="mega-item-header">
                                    <img src="@/assets/images/ui/icon_4.png" alt="企业级知识中枢">
                                    <h4>企业级知识中枢</h4>
                                    <span>智能知识管理</span>
                                  </div>
                                </div>
                                <div class="mega-item">
                                  <div class="mega-item-header">
                                    <img src="@/assets/images/ui/icon_4.png" alt="教育认知引擎">
                                    <h4>教育认知引擎</h4>
                                    <span>智慧教育助手</span>
                                  </div>
                                </div>
                                <div class="mega-item">
                                  <div class="mega-item-header">
                                    <img src="@/assets/images/ui/icon_4.png" alt="新一代智能客服">
                                    <h4>新一代智能客服</h4>
                                    <span>全天候服务</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <!-- AI体验下拉菜单 -->
                  <div v-if="item.type === 'ai'" class="mega-dropdown" :class="{'show': activeDropdown === index}">
                    <div class="mega-dropdown-overlay" @click="closeDropdown"></div>
                    <div class="mega-dropdown-container">
                      <div class="mega-dropdown-content">
                        <div class="mega-section">
                          <div class="mega-section-header">
                            <h2>AI 工具体验</h2>
                            <p>体验前沿AI技术带来的便捷</p>
                          </div>
                          <div class="mega-section-grid">
                            <div class="mega-category">
                              <h3>智能工具</h3>
                              <div class="mega-category-content">
                                <div class="mega-item">
                                  <div class="mega-item-header">
                                    <img src="@/assets/images/ui/icon_4.png" alt="AI翻译">
                                    <h4>AI翻译</h4>
                                    <span>多语言智能翻译</span>
                                  </div>
                                </div>
                                <div class="mega-item">
                                  <div class="mega-item-header">
                                    <img src="@/assets/images/ui/icon_4.png" alt="代码解释器">
                                    <h4>代码解释器</h4>
                                    <span>智能代码分析</span>
                                  </div>
                                </div>
                                <div class="mega-item">
                                  <div class="mega-item-header">
                                    <img src="@/assets/images/ui/icon_4.png" alt="会议纪要">
                                    <h4>会议纪要助手</h4>
                                    <span>自动会议记录</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <!-- 移动端菜单按钮 -->
              <div class="mobile-toggle" @click="toggleMobileMenu">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- 移动端菜单 -->
    <div class="mobile-menu" :class="{'show': store.showMobileMenu}">
      <div class="mobile-menu-inner">
        <div class="mobile-menu-header">
          <div class="logo">
            <img v-lazy="require('@/assets/images/ivxilogo.png')" alt="维熙科技" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7">
          </div>
          <div class="close-btn" @click="toggleMobileMenu">&times;</div>
        </div>
        <div class="mobile-menu-content">
          <div 
            v-for="(item, index) in navItems" 
            :key="index" 
            class="mobile-nav-item"
            :class="{'active': isActive(item.path)}"
          >
            <div class="mobile-nav-link" @click="mobileNavClick(item)">
              <span>{{ item.label }}</span>
              <svg v-if="item.hasDropdown" viewBox="0 0 24 24" width="24" height="24" class="arrow" :class="{'open': mobileExpandedItem === index}">
                <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
              </svg>
            </div>
            <div class="mobile-submenu" :class="{'open': mobileExpandedItem === index}">
              <div v-if="item.type === 'product'">
                <h4>工作+AI</h4>
                <router-link to="/product/content-creation" class="mobile-submenu-item">内容创作</router-link>
                <router-link to="/product/solution-writing" class="mobile-submenu-item">方案撰写</router-link>
                
                <h4>业务+AI</h4>
                <router-link to="/product/smart-qa" class="mobile-submenu-item">智能问答</router-link>
                <router-link to="/product/capability-expansion" class="mobile-submenu-item">能力拓展</router-link>
                <router-link to="/product/private-model" class="mobile-submenu-item">私有模型</router-link>
              </div>
              
              <div v-if="item.type === 'solution'">
                <router-link to="/solution/enterprise-knowledge" class="mobile-submenu-item">企业级知识中枢</router-link>
                <router-link to="/solution/education-engine" class="mobile-submenu-item">教育认知引擎</router-link>
                <router-link to="/solution/smart-service" class="mobile-submenu-item">新一代智能客服</router-link>
              </div>
              
              <div v-if="item.type === 'ai'">
                <router-link to="/ai/translator" class="mobile-submenu-item">AI翻译</router-link>
                <router-link to="/ai/code-interpreter" class="mobile-submenu-item">代码解释器</router-link>
                <router-link to="/ai/meeting-summary" class="mobile-submenu-item">会议纪要助手</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { useMainStore } from '@/stores/main'

const store = useMainStore()
const route = useRoute()
const isSticky = ref(false)
const activeDropdown = ref<number | null>(null)
const mobileExpandedItem = ref<number | null>(null)

// 导航项目
const navItems = ref([
  { label: '首页', path: '/', hasDropdown: false, type: 'home' },
  { label: '产品/服务', path: '/product.html', hasDropdown: true, type: 'product' },
  { label: '解决方案', path: '/solution.html', hasDropdown: true, type: 'solution' },
  { label: 'AI 体验', path: '/AIAssistant.html', hasDropdown: true, type: 'ai' },
  { label: '联系我们', path: '/about.html', hasDropdown: false, type: 'about' }
])

// 检查当前路由是否激活
const isActive = (path: string) => {
  if (path === '/') {
    return route.path === path
  }
  return route.path.startsWith(path.split('.')[0])
}

// 新增关闭下拉菜单方法
const closeDropdown = () => {
  activeDropdown.value = null
}

// 处理下拉菜单显示
const handleMouseEnter = (index: number) => {
  if (navItems.value[index].hasDropdown) {
    activeDropdown.value = index
  }
}

const handleMouseLeave = () => {
  activeDropdown.value = null
}

// 移动端菜单处理
const toggleMobileMenu = () => {
  store.toggleMobileMenu()
}

// 也可以添加一个关闭按钮专用的方法
const closeMobileMenu = () => {
  store.setShowMobileMenu(false)
}

const mobileNavClick = (item: any) => {
  if (!item.hasDropdown) {
    store.toggleMobileMenu()
    return
  }
  
  const index = navItems.value.findIndex(nav => nav.label === item.label)
  mobileExpandedItem.value = mobileExpandedItem.value === index ? null : index
}

// 监听滚动，控制导航栏样式
const handleScroll = () => {
  isSticky.value = window.scrollY > 50
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  handleScroll() // 初始检查
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style lang="scss">
.sticky-wrapper {
  position: relative;
  z-index: 100;
  width: 100%;
  
  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.header {
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  width: 100%;
  
  &.stuck {
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
  }
}

.header-relative {
  position: relative;
}

.wp1280 {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
}

.header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.header-left {
  display: flex;
  align-items: center;
  
  .logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: all 0.3s ease;
    
    img {
      height: 32px;
      transition: all 0.3s ease;
      filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
      
      &:hover {
        transform: scale(1.02);
      }
    }
  }
  
  .logo-tag {
    position: relative;
    margin-left: 16px;
    padding-left: 16px;
    
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 20px;
      background: linear-gradient(to bottom, rgba(22, 119, 255, 0.2), rgba(22, 119, 255, 0.1));
    }
    
    .txt {
      font-size: 18px;
      font-weight: 600;
      background: linear-gradient(135deg, #1677ff, #0052cc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      letter-spacing: 0.5px;
      position: relative;
      
      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, #1677ff, transparent);
        opacity: 0;
        transform: scaleX(0);
        transform-origin: left;
        transition: all 0.3s ease;
      }
    }
    
    &:hover {
      .txt::after {
        opacity: 1;
        transform: scaleX(1);
      }
    }
  }
}

.header-right {
  display: flex;
  align-items: center;
  
  .nav-list {
    display: flex;
    
    @media (max-width: 991px) {
      display: none;
    }
  }
  
  .nav-item {
    position: relative;
    margin: 0 20px;
    
    &:first-child {
      margin-left: 0;
    }
    
    &:last-child {
      margin-right: 0;
    }
    
    &.has-dropdown:hover .dropdown-menu,
    &.has-dropdown:focus-within .dropdown-menu {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
    
    &.is-active .nav-link a {
      color: #1677ff;
      
      &:after {
        transform: scaleX(1);
      }
    }
    
    .nav-link {
      a {
        color: #333;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
        display: block;
        padding: 20px 0;
        position: relative;
        
        &:after {
          content: '';
          position: absolute;
          bottom: 12px;
          left: 0;
          width: 100%;
          height: 2px;
          background: #1677ff;
          transform: scaleX(0);
          transition: transform 0.3s;
        }
        
        &:hover {
          color: #1677ff;
          
          &:after {
            transform: scaleX(1);
          }
        }
      }
    }
  }
}

// 下拉菜单基础样式
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(10px);
  background: white;
  border-radius: 4px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  z-index: 999;
  overflow: hidden;
  min-width: 250px;
  padding: 20px 0;
  
  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(0);
  }
  
  .dropdown-container {
    padding: 0 30px;
    width: 100%;
  }
  
  .section-title {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 20px;
    color: #333;
    position: relative;
    padding-bottom: 12px;
    
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -8px;
      width: 30px;
      height: 2px;
      background: #0e5ecc;
    }
  }
}

// 产品下拉菜单特殊样式
.product-dropdown {
  width: 750px;
  max-width: 90vw;
  
  .section-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  
  .category {
    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      
      img {
        width: 28px;
        height: 28px;
        margin-right: 10px;
        object-fit: contain;
      }
      
      h4 {
        font-size: 16px;
        font-weight: 500;
        color: #333;
        margin: 0;
        
        span {
          font-size: 14px;
          color: #666;
          font-weight: normal;
          margin-left: 5px;
        }
      }
    }
    
    &-items {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      
      .item {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 4px;
        background: #f7f9fc;
        width: calc(50% - 8px);
        text-decoration: none;
        transition: all 0.3s;
        
        &:hover {
          background: #eef5ff;
          
          .text {
            color: #1677ff;
          }
        }
        
        .icon {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          
          img {
            width: 100%;
            height: 100%;
          }
        }
        
        .text {
          color: #333;
          font-size: 14px;
          transition: color 0.3s;
        }
      }
    }
  }
}

// 解决方案和AI体验下拉菜单样式
.solution-dropdown,
.ai-dropdown {
  width: 680px;
  max-width: 90vw;
  
  .solution-items,
  .ai-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    
    .solution-item,
    .ai-item {
      display: flex;
      align-items: center;
      padding: 15px;
      border-radius: 4px;
      background: #f7f9fc;
      min-width: 200px;
      flex: 1;
      text-decoration: none;
      transition: all 0.3s;
      
      @media (max-width: 768px) {
        flex: 0 0 100%;
      }
      
      &:hover {
        background: #eef5ff;
        
        .text {
          color: #1677ff;
        }
      }
      
      .icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      
      .text {
        color: #333;
        font-size: 15px;
        transition: color 0.3s;
      }
    }
  }
}

// 移动端菜单按钮
.mobile-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
  
  @media (max-width: 991px) {
    display: flex;
  }
  
  span {
    display: block;
    width: 100%;
    height: 2px;
    background: #333;
    transition: all 0.3s;
  }
}

// 移动端菜单
.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.97);
  z-index: 1000;
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: transform 0.3s ease-in-out;
  
  @media (max-width: 991px) {
    display: block;
  }
  
  &.show {
    opacity: 1;
    visibility: visible;
  }
  
  .mobile-menu-inner {
    padding: 80px 20px 40px;
    height: 100%;
    overflow-y: auto;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }
  
  &.show .mobile-menu-inner {
    transform: translateX(0);
  }
  
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #eee;
    
    .logo img {
      height: 30px;
    }
  }
  
  &-content {
    padding: 20px;
  }
  
  .mobile-nav-item {
    margin-bottom: 15px;
    
    &.active .mobile-nav-link span {
      color: #1677ff;
    }
    
    .mobile-nav-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      cursor: pointer;
      
      span {
        font-size: 16px;
        font-weight: 500;
        color: #333;
      }
      
      .arrow {
        fill: #333;
        transition: all 0.3s;
        
        &.open {
          transform: rotate(180deg);
        }
      }
    }
    
    .mobile-submenu {
      padding-left: 15px;
      max-height: 0;
      overflow: hidden;
      transition: all 0.3s;
      
      &.open {
        max-height: 500px;
        margin-top: 10px;
        margin-bottom: 15px;
      }
      
      h4 {
        font-size: 15px;
        font-weight: 500;
        color: #333;
        margin: 15px 0 10px;
        
        &:first-child {
          margin-top: 0;
        }
      }
      
      &-item {
        display: block;
        padding: 8px 0;
        font-size: 14px;
        color: #666;
        text-decoration: none;
        
        &:hover {
          color: #1677ff;
        }
      }
    }
  }
  
  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    z-index: 100;
  }
}

// 响应式样式
@media (max-width: 768px) {
  .header-nav {
    height: 60px;
  }
  
  .header-left {
    .logo img {
      height: 28px;
    }
    
    .logo-tag {
      margin-left: 12px;
      padding-left: 12px;
      
      .txt {
        font-size: 16px;
      }
      
      &::before {
        height: 16px;
      }
    }
  }
}

// 新的Mega下拉菜单样式
.mega-dropdown {
  position: fixed;
  left: 0;
  right: 0;
  top: 80px;
  background: transparent;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 1000;
  
  &.show {
    visibility: visible;
    opacity: 1;
  }
  
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(4px);
    z-index: -1;
    cursor: pointer;
  }
  
  &-container {
    background: white;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
    border-top: 1px solid #eee;
  }
  
  &-content {
    max-width: 1280px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .mega-section {
    &-header {
      text-align: center;
      margin-bottom: 20px;
      
      h2 {
        font-size: 24px;
        font-weight: 600;
        color: #333;
        margin: 0 0 5px;
      }
      
      p {
        font-size: 16px;
        color: #666;
        margin: 0;
      }
    }
    
    &-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
  }
  
  .mega-category {
    h3 {
      font-size: 18px;
      font-weight: 500;
      color: #333;
      margin: 0 0 15px;
      padding-bottom: 10px;
      border-bottom: 2px solid #f0f0f0;
    }
    
    &-content {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
  
  .mega-item {
    background: #f7f9fc;
    border-radius: 8px;
    padding: 15px;
    transition: all 0.3s ease;
    
    &:hover {
      background: #eef5ff;
      transform: translateY(-2px);
    }
    
    &.featured {
      background: linear-gradient(135deg, #f7f9fc, #eef5ff);
      
      img {
        width: auto !important;
        height: auto !important;
        max-width: 100%;
      }
    }
    
    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      
      img {
        width: 28px;
        height: 28px;
        margin-right: 12px;
        object-fit: contain;
      }
      
      h4 {
        font-size: 15px;
        font-weight: 500;
        color: #333;
        margin: 0;
      }
      
      span {
        font-size: 13px;
        color: #666;
        margin-left: 8px;
      }
    }
    
    &-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
      
      a {
        color: #666;
        text-decoration: none;
        font-size: 13px;
        padding: 6px;
        border-radius: 4px;
        transition: all 0.3s;
        
        &:hover {
          color: #1677ff;
          background: rgba(22, 119, 255, 0.1);
        }
      }
    }
  }
}

// 响应式调整
@media (max-width: 1200px) {
  .mega-dropdown {
    .mega-section-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }
  }
}

@media (max-width: 768px) {
  .mega-dropdown {
    .mega-section-grid {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }
}
</style> 