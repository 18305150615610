import { Directive, DirectiveBinding } from 'vue'

export const vAos: Directive = {
  beforeMount(el: HTMLElement, binding: DirectiveBinding) {
    // 设置data-aos属性
    el.setAttribute('data-aos', binding.value || 'fade-up')
    
    // 如果有延迟参数
    if (binding.modifiers.delay) {
      el.setAttribute('data-aos-delay', '200')
    }
    
    // 如果有持续时间参数
    if (binding.modifiers.duration) {
      el.setAttribute('data-aos-duration', '1000')
    }
  }
} 