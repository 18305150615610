// 如果上面导入仍有问题，尝试下面这种方式
// import { createPinia, setActivePinia, Store } from 'pinia'
// const pinia = createPinia()
// setActivePinia(pinia)

// 直接实现简单store逻辑
import { ref, reactive } from 'vue'

export function useMainStore() {
  const isLoading = ref(false)
  const showMobileMenu = ref(false)
  
  function setLoading(status: boolean) {
    isLoading.value = status
  }
  
  function setShowMobileMenu(status: boolean) {
    showMobileMenu.value = status
  }
  
  function toggleMobileMenu() {
    showMobileMenu.value = !showMobileMenu.value
    console.log('切换后的菜单状态:', showMobileMenu.value)
  }
  
  return {
    isLoading,
    showMobileMenu,
    setLoading,
    setShowMobileMenu,
    toggleMobileMenu
  }
} 