import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, onMounted, onUnmounted } from 'vue';

// 状态

export default /*@__PURE__*/_defineComponent({
  __name: 'BackToTop',
  setup(__props) {

const isVisible = ref(false);

// 滚动监听处理
const handleScroll = () => {
  isVisible.value = window.scrollY > 300;
};

// 滚动到顶部
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

// 生命周期钩子
onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["back-to-top", { 'visible': isVisible.value }]),
    onClick: scrollToTop
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("svg", {
      viewBox: "0 0 24 24",
      width: "24",
      height: "24"
    }, [
      _createElementVNode("path", {
        fill: "currentColor",
        d: "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"
      })
    ], -1)
  ]), 2))
}
}

})